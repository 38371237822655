<template>
  <HeaderContain>
    <div class="doc-page__contain">
      <div ref="menu" class="doc-page__menu">
        <div class="doc-page_container">
          <div class="doc-page__menu__title">{{ title }}</div>
          <a
            v-for="item in titles"
            :class="{ active: activeTitle === item }"
            :key="item"
            href="javascript:void(0)"
            @click="handleClick(item)"
          >
            {{ item }}
          </a>
        </div>
      </div>
      <slot />
    </div>
  </HeaderContain>
</template>

<script>
import HeaderContain from '@/components/HeaderContain.vue';

export default {
  name: 'DocPage',
  components: { HeaderContain },
  props: {
    title: {
      type: String,
      default: '',
      require: true,
    },
    titles: {
      type: Array,
      default: () => [],
      require: true,
    },
  },
  data() {
    return {
      activeTitle: '',
    };
  },
  methods: {
    handleScroll() {
      const titles = document.getElementsByTagName('h2');
      const target = [...titles].find(
        item => item.getBoundingClientRect().top > 0,
      );
      this.activeTitle = target ? target.id : '';
    },
    handleClick(target) {
      this.activeTitle = target;
      const titles = document.getElementById(target);
      if (titles) {
        titles.scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
  },
  mounted() {
    this.handleScroll();
    document.body.addEventListener('mousewheel', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.doc-page__contain {
  padding: 0 12px 24px 12px;
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.6);
  font-family: AvenirLT-Regular, "Open Sans", sans-serif;
  font-weight: 400;

  /deep/ h1 {
    margin: 24px 0 0 0;
    padding: 0;
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    font-family: AvenirLT-Medium, PingFang SC, Microsoft YaHei, SimHei,
      sans-serif;
    color: #000;
  }

  /deep/ p {
    font-size: 12px;
    margin: 8px 0 12px 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.3);
    line-height: 12px;
  }

  /deep/ h2 {
    font-weight: 500;
    font-family: AvenirLT-Medium, PingFang SC, Microsoft YaHei, SimHei,
      sans-serif;
    margin-top: 24px;
    font-size: 16px;
    line-height: 16px;
    color: #000;
  }
}

.doc-page__menu {
  display: none;
}

@media (min-width: 750px) {
  .doc-page__contain {
    margin-left: 297px;
    width: 700px;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 200px;

    /deep/ h1 {
      margin: 80px 0 0 0;
      font-size: 26px;
      line-height: 37px;
    }

    /deep/ p {
      font-size: 12px;
      margin: 10px 0 50px 0;
      line-height: 17px;
    }

    /deep/ h2 {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .doc-page__menu {
    display: initial;
    position: fixed;
    top: 88px;
    left: 40px;
    width: 207px;
    .doc-page_container {
      height: calc(100vh - 88px);
      overflow: scroll;
    }

    /deep/ .doc-page__menu__title {
      font-size: 12px;
      font-weight: 500;
      font-family: AvenirLT-Medium, PingFang SC, Microsoft YaHei, SimHei,
        sans-serif;
      color: rgba(0, 0, 0, 0.3);
      line-height: 17px;
    }

    /deep/ a {
      font-size: 14px;
      display: block;
      margin-top: 30px;
      font-family: AvenirLT-Medium, PingFang SC, Microsoft YaHei, SimHei,
        sans-serif;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.3);
      line-height: 20px;
      transition: color 0.5s;
      cursor: pointer;
      text-decoration: none;

      &.active {
        color: #000;
        transition: color 0.5s;
      }
    }
  }
}
</style>
