<template>
  <div class="header-contain">
    <header class="header-contain__header">
      <a href="../">
        <img class="logo-header__logo--m" src="@/assets/logo.svg" alt="logo" />
        <img
          class="logo-header__logo--pc"
          src="@/assets/logo.svg"
          alt="logo"
        />
      </a>
      <slot name="header" />
    </header>
    <main class="header-contain__main">
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: 'HeaderContain',
};
</script>

<style lang="scss" scoped>
@import "~@/styles/var.scss";

.header-contain {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.header-contain__header {
  height: 1rem;
  line-height: 1rem;
  text-align: center;
  background: #fff;
  z-index: 2;
  border-bottom: 1px solid #f2f2f2;
}

.header-contain__main {
  flex: 1;
  overflow: auto;
}

.logo-header__logo--m {
  display: initial;
  height: 0.42rem;
  vertical-align: top;
  margin-top: 0.29rem;
}

.logo-header__logo--pc {
  display: none;
}

@media (min-width: 750px) {
  .header-contain__header {
    padding-left: 30px;
    height: 66px;
    line-height: 66px;
    text-align: left;
  }

  .logo-header__logo--m {
    display: none;
  }

  .logo-header__logo--pc {
    height: 24px;
    vertical-align: top;
    margin-top: 20px;
    display: initial;
  }
}
</style>
